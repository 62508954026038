<template>
    <b-container fluid>
         <card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('elearning_tim.trainer_wise_evaluation')}} {{$t('globalTrans.search')}}</h4>
          </template>
          <template v-slot:body>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="3"
                    :label="$t('globalTrans.mobile')"
                    label-for="mobile"
                    >
                    <b-form-input
                        type="number"
                        id="mobile"
                        v-model="search.mobile"
                        placeholder=""
                        ></b-form-input>
                    </b-form-group>
                </b-col>
              <b-col lg="12" sm="12">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
          </template>
        </card>
        <b-row>
            <b-col md="12">
                 <card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('elearning_tim.trainer_wise_evaluation')}} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                      {{ $n(data.index + 1) }}
                                      </template>
                                      <template v-slot:cell(training_title)="data">
                                        <span class="capitalize">{{ data.item.training_title }}</span>
                                      </template>
                                      <template v-slot:cell(addressData)="data">
                                        <span>{{ (($i18n.locale==='bn') ? data.item.office_name_bn : data.item.office_name) + ', ' + (($i18n.locale==='bn') ? data.item.org_name_bn : data.item.org_name) }}</span>
                                      </template>
                                      <template v-slot:cell(status)="data">
                                        <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                        <!-- <b-button v-b-modal.modal-4 variant=" iq-bg-warning mr-1 mb-1" size="sm" @click="edit(data.item)">
                                          <i class="fas fa-eye"></i>
                                        </b-button> -->
                                        <router-link :to="`trainer-wise-evaluation-details?id=${data.item.id}`" :class="'btn btn-success text-light'"><i class="fas fa-eye"></i></router-link>
                                      </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </card>
            </b-col>
        </b-row>
        <!-- <b-modal id="modal-4" size="lg" :title="$t('elearning_tpm.course_managment') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <template #modal-title class="flex-grow-1">
            {{ $t('elearning_tim.trainer_wise_evaluation') + ' ' + $t('globalTrans.details') }}
            <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
              {{  $t('globalTrans.export_pdf') }}
            </b-button>
          </template>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal> -->
    </b-container>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainerWiseEvaluationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import flatpickr from 'flatpickr'
// import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
    },
    data () {
      return {
        search: {
          mobile: ''
        },
        courseList: []
      }
    },
    computed: {
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      trainingTitleList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.update')
      },
      trainerEvaluationList: function () {
        return this.$store.state.ExternalUserTraining.traineeTranerObj.trainerEvaluationList
      },
      columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('globalTrans.name'), class: 'text-left' },
          { label: this.$t('globalTrans.mobile'), class: 'text-left' },
          { label: this.$t('globalTrans.address'), class: 'text-left' },
          { label: this.$t('elearning_config.title'), class: 'text-left' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
          keys = [
          { key: 'index' },
          { key: 'name_bn' },
          { key: 'mobile' },
          { key: 'addressData' },
          { key: 'grade' },
          { key: 'action' }
          ]
        } else {
          keys = [
          { key: 'index' },
          { key: 'name' },
          { key: 'mobile' },
          { key: 'addressData' },
          { key: 'grade' },
          { key: 'action' }
          ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      }
    },
    watch: {
      'search.training_title_id': function (newValue) {
        this.courseList = this.getCourseList(newValue)
      }
    },
    async created () {
        this.search = Object.assign({}, this.search, {
            org_id: this.$store.state.dataFilters.orgId
        })
      this.loadData()
    },
    mounted () {
      flatpickr('.fromDate', {})
    },
    methods: {
      getCourseList (trainingTitleId) {
        const courseList = this.$store.state.TrainingElearning.commonObj.courseList
        if (trainingTitleId) {
          return courseList.filter(item => item.training_title_id === trainingTitleId)
        }
        return courseList
      },
      async searchData () {
        this.loadData()
      },
      loadData () {
          const params = Object.assign({}, this.search)
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, trainerWiseEvaluationList, params).then(response => {
            if (response.success) {
                 this.$store.dispatch('setList', this.getCustomDataList(response.data))
                this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const orgItem = {}
            const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === item.professional_info.org_id)
            if (typeof orgObj !== 'undefined') {
              orgItem.org_name = orgObj.text_en
              orgItem.org_name_bn = orgObj.text_bn
            } else {
              orgItem.org_name = ''
              orgItem.org_name_bn = ''
            }

          const officeObj = this.$store.state.commonObj.officeList.find(doc => doc.value === parseInt(item.professional_info.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office_name = officeObj.text_en
            officeData.office_name_bn = officeObj.text_bn
          } else {
            officeData.office_name = ''
            officeData.office_name_bn = ''
          }
          return Object.assign({}, item, orgItem, officeData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      }
    }
}
</script>

<style>
.modal-title {
  flex-grow: 1 !important;
}
</style>
